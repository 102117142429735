import { breakpoints, colors, misc, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

import { ContentProps, DropdownProps } from './types';

type ContainerProps = Pick<DropdownProps, 'hideOnMobile' | 'isLangSelector'>;

export const Container = styled.div<ContainerProps>`
  position: relative;
  cursor: pointer;
  display: ${({ hideOnMobile }) => hideOnMobile && 'none'};

  ${({ isLangSelector }) =>
    isLangSelector &&
    css`
      position: absolute;
      bottom: 16px;
      right: 32px;
    `}

  @media (min-width: ${breakpoints.minDesktop}) {
    display: block;
  }
`;

export const Content = styled.nav<ContentProps>`
  cursor: auto;
  position: fixed; //TODO: find solution with absolute, so it aligns under the parent
  top: 90px;
  right: ${({ isLangSelector }) => (isLangSelector ? '12px' : '50px')};
  background-color: ${colors.white};
  border-radius: 4px;
  box-shadow: ${misc.shadow_menu};
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 999;

  @media (min-width: ${breakpoints.minDesktop}) {
    top: 55px;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `}

  :before, :after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 19px;
    border: 11px solid transparent;
    border-bottom-color: #dddddd;
  }

  :after {
    right: 20px;
    border: 10px solid transparent;
    border-bottom-color: #ffffff;
  }
`;

export const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  text-align: left;
  padding: 0;
  margin: ${spacing.m} ${spacing.l};
`;
