import { ChevronFilledIcon } from '@hp/atomic';
import { useI18n } from '@hp/locale';
import { colors, typography } from '@hp/theme';
import { MessageDescriptor } from '@lingui/core';
import React from 'react';
import styled from 'styled-components';

import { InputsInfoIcons } from './Input';
import { Checkmark, CheckmarkWrapper, Exclamation } from './styled';

export type SelectProps<
  T extends React.OptionHTMLAttributes<HTMLOptionElement>['value'] = string
> = {
  className?: string;
  noCheckmark?: boolean;
  firstOption?: MessageDescriptor | true;
  lastOption?: MessageDescriptor | true;
  error?: boolean;
  touched?: boolean;
  moveChevron?: boolean;
  preview?: boolean;
  options: Array<{ value: T; label: MessageDescriptor | string }>;
  withLabel?: boolean;
} & Omit<
  React.HTMLProps<HTMLSelectElement>,
  'options' | 'as' | 'ref' | 'label'
> /* due to ts/react conflict */;

type SelectStyledProps = Pick<SelectProps, 'error' | 'touched' | 'preview'>;

const SelectStyled = styled.select<SelectStyledProps>`
  color: ${colors.black};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};

  border: none;
  outline: none;

  transition: border-bottom-color linear 0.5s;
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  -webkit-appearance: none;
  position: absolute;
  top: -30px;
  padding-top: 26px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

type ChevronProps = {
  moveChevron?: boolean;
  hide?: boolean;
};

const RightZone = styled.div`
  position: absolute;
  background-color: ${colors.input};
  pointer-events: none;
  right: 0px;
  top: 0px;
  width: 27px;
  height: 18px;
`;

const Chevron = styled(ChevronFilledIcon)<ChevronProps>`
  display: ${({ hide }) => hide && 'none'};
  width: 20px;
  position: absolute;
  top: -2px;
  right: ${({ moveChevron }) => (moveChevron ? '12px' : '4px')};
  z-index: 2;
  pointer-events: none;
  background-color: ${colors.input};
`;

export const Select = function <
  T extends React.OptionHTMLAttributes<HTMLOptionElement>['value'] = string
>({
  className,
  firstOption,
  lastOption,
  error,
  moveChevron,
  preview,
  options,
  onChange,
  touched,
  value,
  onBlur,
  noCheckmark = false,
  withLabel,
  ...props
}: SelectProps<T>) {
  const i18n = useI18n();

  return (
    <Wrapper className={className}>
      <SelectStyled
        onChange={onChange}
        onBlur={onBlur}
        value={value || value === 0 ? value : ''}
        error={error}
        touched={touched}
        preview={preview}
        disabled={preview}
        {...props}
      >
        {firstOption && (
          <option key="-" value="" disabled>
            {firstOption === true ? '' : i18n._(firstOption)}
          </option>
        )}

        {options.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {typeof label === 'string' ? label : i18n._(label)}
          </option>
        ))}

        {lastOption && (
          <option key="-" value="" disabled>
            {lastOption === true ? '' : i18n._(lastOption)}
          </option>
        )}
      </SelectStyled>
      <RightZone>
        <Chevron
          color={colors.black}
          moveChevron={moveChevron || (!noCheckmark && touched)}
          hide={preview}
        />
        <InputsInfoIcons withLabel={withLabel} forSelect>
          {error && <Exclamation>!</Exclamation>}
          {!noCheckmark && touched && !error && (
            <CheckmarkWrapper>
              <Checkmark />
            </CheckmarkWrapper>
          )}
        </InputsInfoIcons>
      </RightZone>
    </Wrapper>
  );
};
