import { RouteNameType } from '@hp/seo';
import React, { FC } from 'react';

import { LanguageSelectorDropdown } from './LanguageSelector';
import { Logo, LogoLink, LogoWrapper, Nav } from './styled';

type NavigationProps = {
  hideLogoOnNarrow?: boolean;
  logo?: JSX.Element;
  logoRouteTo?: RouteNameType;
  onlyLangSwitch?: boolean;
};

export const Navigation: FC<NavigationProps> = ({
  children,
  hideLogoOnNarrow,
  logo = <Logo />,
  logoRouteTo = 'landing',
  onlyLangSwitch = false,
}) => {
  return (
    <>
      <Nav>
        <LogoWrapper hideLogoOnNarrow={hideLogoOnNarrow}>
          <LogoLink to={logoRouteTo} key="logo">
            {logo}
          </LogoLink>
        </LogoWrapper>

        {onlyLangSwitch ? <LanguageSelectorDropdown /> : <>{children}</>}
      </Nav>
    </>
  );
};
