import React, { FC, useEffect, useState } from 'react';

import { Container, Content, Item, ItemList } from './styled';
import { DropdownProps } from './types';

export const Dropdown: FC<DropdownProps> = ({
  children,
  items,
  isLangSelector,
  hideOnMobile,
}) => {
  const [active, setActive] = useState(false);

  const toggleActive = () => setActive((previous) => !previous);

  useEffect(() => {
    const pageClickEvent = () => {
      // TODO: maybe use ref to determine where user clicks and then close or do nothing?
      setActive(!active);
    };

    if (active) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [active]);

  return (
    <Container
      onClick={toggleActive}
      hideOnMobile={hideOnMobile}
      isLangSelector={isLangSelector}
    >
      {children}

      {active && items?.length > 0 && (
        <Content active={active} isLangSelector={isLangSelector}>
          <ItemList>
            {items.map((item, index) => (
              <Item key={`item${index}`}>{item}</Item>
            ))}
          </ItemList>
        </Content>
      )}
    </Container>
  );
};
