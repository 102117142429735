import { Language } from '@hp/config';
import { labels } from '@hp/locale';
import {
  getCountryCodeByLanguage,
  getLanguageName,
  languageOptions,
} from '@hp/utils';
import { flag } from 'country-emoji';
import React, { FC, FormEventHandler } from 'react';

import { FormattedValueWrapper, InputWrapper } from '../fields/styled';
import { Label } from './Label';
import { Select, SelectProps } from './Select';

const getCountryLabel = (value: Language) => {
  const country = getCountryCodeByLanguage(value);
  return (
    <span>
      {flag(country)} &nbsp; {getLanguageName(value)}
    </span>
  );
};

type LanguageSelectorProps = Omit<
  SelectProps<string>,
  'value' | 'options' | 'withLabel' | 'onChange'
> & {
  label?: React.ReactNode;
  id?: string;
  value: Language;
  onChange: FormEventHandler<HTMLSelectElement>;
};

export const LanguageSelect: FC<LanguageSelectorProps> = ({
  className,
  onChange,
  value,
  onBlur,
  label = labels.switchLanguage,
  id = 'language_select',
  ...props
}) => {
  return (
    <InputWrapper withLabel forSelect>
      {label && <Label htmlFor={id}>{label}</Label>}

      <Select<string>
        withLabel={!!label}
        id={id}
        options={languageOptions}
        {...props}
        onBlur={(e) => {
          onBlur?.(e);
        }}
        onChange={(e) => {
          onChange?.(e);
        }}
        value={value}
      />

      <FormattedValueWrapper>{getCountryLabel(value)}</FormattedValueWrapper>
    </InputWrapper>
  );
};
