import { colors, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type LabelProps = {
  htmlFor?: string;
};

export const LabelStyled = styled.label`
  color: ${colors.gray_mid};
  font-weight: ${typography.fontWeight.normal};
  font-size: ${typography.fontSize.input.label};
  line-height: ${typography.lineHeight.body};
`;

export const Label: FC<LabelProps> = ({ htmlFor, children, ...props }) => (
  <LabelStyled htmlFor={htmlFor} {...props}>
    {children}
  </LabelStyled>
);
