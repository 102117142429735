import { breakpoints, elements } from '@hp/theme';
import React, {
  Children,
  cloneElement,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
} from 'react';
import styled, { css, keyframes } from 'styled-components';

type MenuItemProps = {
  moveUp?: boolean;
  isButton?: boolean;
  isEmpty?: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LiStyled = styled.li<Omit<MenuItemProps, 'setOpen'>>`
  align-items: center;
  text-align: center;
  width: ${elements.buttonMaxWidth};
  padding: ${({ isEmpty }) => (isEmpty ? '0' : '8px')} 0;
  margin: 0 ${({ isEmpty }) => (isEmpty ? '0' : '5px')};
  margin-bottom: ${({ isEmpty }) => (isEmpty ? '0' : '10px')};
  margin-top: ${({ moveUp }) => moveUp && '-20px'};
  transition-timing-function: ease-in;
  transition: 0.3s;

  ${({ open }) =>
    open
      ? css`
          transform: translateY(0);
          animation: ${fadein} 2s;
          opacity: 1;
        `
      : css`
          transform: translateY(-10px);
          animation: none;
          opacity: 0;
        `}

  @media (min-width: ${breakpoints.minDesktop}) {
    width: auto;
    padding: 0;
    margin: 0 ${({ isEmpty }) => (isEmpty ? '0' : '5px')};
    opacity: 1;
    transform: none;
    transition: none;

    ${({ isButton }) =>
      isButton &&
      css`
        :last-child {
          margin-right: 0;
        }
      `}
  }
`;

export const MenuItem: FC<MenuItemProps> = ({
  moveUp,
  isButton,
  isEmpty,
  open,
  setOpen,
  children,
}) => {
  const childrenRender = Children.map(children, (child) => {
    if (child) {
      return cloneElement(child as ReactElement, {
        setOpen: setOpen,
      });
    }
  });

  return (
    <LiStyled moveUp={moveUp} isButton={isButton} isEmpty={isEmpty} open={open}>
      {childrenRender}
    </LiStyled>
  );
};
