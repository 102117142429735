import { colors, typography } from '@hp/theme';
import styled from 'styled-components';

export const Exclamation = styled.div`
  color: ${colors.error};
  font-size: ${typography.fontSize.bodyLarge};
  line-height: ${typography.lineHeight.bodyLarge};
  pointer-events: none;
`;

export const CheckmarkWrapper = styled.div`
  width: 15px;
  height: 35px;
`;

export const Checkmark = styled.div`
  position: relative;
  content: ' ';
  left: 8px;
  top: 5px;
  width: 6px;
  height: 10px;
  border: solid ${colors.green};
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
`;
