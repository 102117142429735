import {
  Button,
  ButtonType,
  Typography,
  TypographyType,
  UpperCase,
} from '@hp/atomic';
import { Language } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { colors, spacing } from '@hp/theme';
import {
  getCountryCodeByLanguage,
  getLanguageCode,
  getLanguageNameWithCode,
} from '@hp/utils';
import React, { FC } from 'react';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';

import { Dropdown } from '../Dropdown';

const StyledItemButton = styled(Button)<{ active?: boolean }>`
  color: ${({ active }) => (active ? colors.red_main : colors.black)};
  padding: ${spacing.xs};
  text-transform: capitalize;

  :hover {
    color: ${colors.red_main};
    text-decoration: none;
  }
`;

type LanguageItemProps = {
  lang: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  active: boolean;
};

const LanguageItem: FC<LanguageItemProps> = ({ lang, setLanguage, active }) => {
  const country = getCountryCodeByLanguage(lang);

  return (
    <StyledItemButton
      buttonType={ButtonType.TEXT}
      onClick={() => setLanguage(lang)}
      active={active}
    >
      <ReactCountryFlag
        countryCode={country}
        svg
        style={{
          width: '20px',
          height: '18px',
        }}
      />
      &nbsp;&nbsp;&nbsp;
      {getLanguageNameWithCode(lang)}
    </StyledItemButton>
  );
};

type LanguageSelectorDropdownProps = {
  hideOnMobile?: boolean;
};

export const LanguageSelectorDropdown: FC<LanguageSelectorDropdownProps> = ({
  hideOnMobile,
}) => {
  const { language, setLanguage: setLanguageState } = useLanguage();
  const setLanguage = (lang: Language) => {
    setLanguageState(lang);
  };

  return (
    <Dropdown
      items={Object.values(Language).map((lang) => {
        return (
          <LanguageItem
            key={lang}
            lang={lang}
            setLanguage={setLanguage}
            active={lang === language}
          />
        );
      })}
      isLangSelector
      hideOnMobile={hideOnMobile}
    >
      <Typography type={TypographyType.BodyLight} color={colors.black}>
        <UpperCase>{getLanguageCode(language)}</UpperCase>
      </Typography>
    </Dropdown>
  );
};
